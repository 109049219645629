import React from "react";
import * as S from "./styled";
import Cat from "../../images/svgs/cat.svg";
import Devops from "../../images/svgs/rocket-launch.svg";
import Cloud from "../../images/svgs/cloud.svg";
import ESX from "../../images/svgs/logo-icon-RED.svg";

import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";

const CasesSection = ({ cases }) => {
  const { img00, img01, img02, img03 } = useStaticQuery(
    graphql`
      query {
        img00: file(relativePath: { eq: "cover-cat-1_left_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1366, toFormat: WEBP, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img01: file(relativePath: { eq: "cover_home_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1366, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img02: file(relativePath: { eq: "cloud-2_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1366, toFormat: WEBP, fit: COVER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        img03: file(relativePath: { eq: "devops-2-right_960x720px.png" }) {
          childImageSharp {
            fluid(maxWidth: 1366, toFormat: WEBP) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const images = [
    {
      logo: <Cat width="60" height="60" />,
      img: img00.childImageSharp.fluid,
      theme: S.catTheme,
    },
    {
      logo: <Cloud width="80" height="80" />,
      img: img02.childImageSharp.fluid,
      theme: S.easyTheme,
    },
    {
      logo: <Devops width="80" height="80" />,
      img: img03.childImageSharp.fluid,
      theme: S.devopsTheme,
    },
    {
      logo: null,
      img: img01.childImageSharp.fluid,
      theme: S.esxTheme,
    },
  ];

  return (
    <>
      {cases.map((c, index) => (
        <ThemeProvider theme={images[c.caseIndex].theme}>
          <S.CaseWrapper
            style={
              index % 2 === 0 && cases.length > 1
                ? S.invertStyleCaseWrapper
                : null
            }
          >
            <S.CaseItem
              style={
                index % 2 === 0 && cases.length > 1
                  ? S.invertStyleCaseItemWrapper
                  : null
              }
            >
              <S.CaseBorder
                style={
                  index % 2 === 0 && cases.length > 1
                    ? S.invertStyleBorder
                    : null
                }
              />
              <S.CaseItemTextWrapper
                style={
                  index % 2 === 0 && cases.length > 1
                    ? S.invertStyleCaseTextWrapper
                    : null
                }
                data-sal="zoom-in"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                {/* <S.CaseItemTextLabel>{c.label}</S.CaseItemTextLabel> */}
                <S.CaseItemTextTitle
                  style={
                    index % 2 === 0 && cases.length > 1
                      ? S.invertStyleCaseItemTitle
                      : null
                  }
                >
                  {images[c.caseIndex].logo}
                  {c.title}
                </S.CaseItemTextTitle>
                <S.CaseItemText>{c.description}</S.CaseItemText>
                <S.CaseItemTextButton to={c.url}>
                  {c.buttonText}
                </S.CaseItemTextButton>
              </S.CaseItemTextWrapper>
            </S.CaseItem>
            <S.CaseImage fluid={images[c.caseIndex].img}>
              {/* <S.Gradient
                style={index % 2 !== 0 ? S.invertGradientImage : null}
              /> */}
            </S.CaseImage>
          </S.CaseWrapper>
        </ThemeProvider>
      ))}
    </>
  );
};

export default CasesSection;
