import styled from "styled-components";
import media from "styled-media-query";
import { StopMini } from "styled-icons/remix-fill/StopMini";

export const SquareIcon = styled(StopMini)`
  height: 1.2rem;
`;

export const HeroSection = styled.section`
  padding: 2rem var(--default-padding-desktop);
  height: auto;
  background: white;
  ${media.lessThan("medium")`
    padding: 2rem var(--default-padding-mobile);
  `}
`;
export const ItemRow = styled.div``;
export const SliderWrapper = styled.section`
  height: 100%;
  margin: 3rem 0;
  ${media.lessThan("medium")`
  margin:  0;
  `}
  svg {
    fill: var(--esx-red);
  }
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }
  .indicator {
    z-index: 3;
    padding: 1rem;
    cursor: pointer;
    text-align: center;
    color: #fafafa;
    color: var(--esx-red);
  }
  .indicator.active {
    svg {
      transition: transform 500ms;
      transform: scale(2);
    }
    color: var(--esx-red);
  }
`;
export const Slide = styled.div`
  display: flex;
  align-content: center;
  padding: 0 var(--default-padding-desktop);
  & > * + * {
    margin: 0;
    margin-left: 2rem;
  }
  ${media.lessThan("large")`
    padding: var(--default-padding-mobile);
    /* Substituto pro Gap em Safari */
    & > * + * {
      margin: 0;
      margin-bottom: 2rem;
    }

  `}
  ${media.lessThan("small")`
    flex-direction: column-reverse;
    padding-bottom: 0;
  `}
`;
export const SliderContentWrapper = styled.div`
  height: 100%;
`;
export const ItemCol = styled.div`
  height: auto;
  width: 100px;
`;
export const ItemImage = styled.figure`
  height: 100%;
  width: 100%;
  filter: grayScale(1);
`;
