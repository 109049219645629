import React from "react";
import * as S from "./styled";

import Cat from "../../images/svgs/cat.svg";
import Devops from "../../images/svgs/rocket-launch.svg";
import Cloud from "../../images/svgs/cloud.svg";
import { ThemeProvider } from "styled-components";

const ServicesSection = () => {
  return (
    <>
      <S.ServicesSection>
        <S.Column>
          <S.TextTitle>ESX</S.TextTitle>
          <S.TextDescription>
            Somos uma consultoria de tecnologia focada em ajudar empresas a
            gerarem novas vantagens competitivas e escolherem o melhor caminho
            para o digital, resolvendo seus problemas de forma pragmática ou, se
            necessário, trabalhando em transformações estruturais e culturais
            progressivamente.
          </S.TextDescription>
        </S.Column>
        <S.Column>
          <S.ServicesWrapper>
            <ThemeProvider theme={S.devopsTheme}>
              <S.ItemImage
                data-sal="zoom-in"
                data-sal-duration="500"
                data-sal-delay="300"
                data-sal-easing="ease"
                to="/devops-consulting"
              >
                <Devops width="50" height="50" />
                <S.ItemDescription>Devops Consulting</S.ItemDescription>
              </S.ItemImage>
            </ThemeProvider>
            <ThemeProvider theme={S.easyTheme}>
              <S.ItemImage
                data-sal="zoom-in"
                data-sal-duration="500"
                data-sal-delay="200"
                data-sal-easing="ease"
                to="/easy-cloud-and-applications"
              >
                <Cloud width="50" height="50" />
                <S.ItemDescription>
                  Easy Cloud and Applications
                </S.ItemDescription>
              </S.ItemImage>
            </ThemeProvider>
            <ThemeProvider theme={S.catTheme}>
              <S.ItemImage
                data-sal="zoom-in"
                data-sal-duration="500"
                data-sal-delay="100"
                data-sal-easing="ease"
                to="/cat"
              >
                <Cat width="50" height="50" />
                <S.ItemDescription>Customer Analytics Tool</S.ItemDescription>
              </S.ItemImage>
            </ThemeProvider>
          </S.ServicesWrapper>
        </S.Column>
      </S.ServicesSection>
    </>
  );
};

export default ServicesSection;
