import styled from "styled-components";
import { Link } from "gatsby";
import media from "styled-media-query";

//styled components
export const ServicesSection = styled.section`
  background-image: var(--dark-gradient);
  height: 70vh;
  display: flex;
  gap: 2rem;
  align-content: center;
  padding: 3rem 10rem;
  ${media.lessThan("large")`
    flex-direction: column;
    height: auto;
    padding: 1.5rem;
    
  `}
  ${media.greaterThan("1900px")`   
    padding: 10rem 30rem;
    height: auto
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  width: 50%;
  ${media.lessThan("large")`
    width: 100%;
    margin-bottom: 1rem;
  `}
`;
export const ServicesWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  height: auto;
  margin: auto;
  ${media.lessThan("small")`
    flex-direction: column;
    margin: 0;
  `}
`;
export const ItemImage = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  align-items: center;
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #fafafa;
  border-bottom: 0.75rem solid ${(props) => props.theme.main};
  transition: all 300ms ease-in-out;
  width: 50%;
  border-radius: 5px;
  ${media.lessThan("small")`
    width: 100%;
    margin-left: 0;
  `}
`;

export const TextTitle = styled.h1`
  color: #fafafa;
  font-size: var(--large-title-size);
  ${media.lessThan("small")`
  `}
`;
export const TextDescription = styled.h2`
  color: #fafafa;
`;
export const ItemDescription = styled.p`
  color: #fafafa;
  text-transform: uppercase;
  font-size: var(--label-size);
  margin: 1rem;
`;
//props
ItemImage.defaultProps = {
  theme: {
    main: "var(--esx-red)",
  },
};

//themes
export const catTheme = {
  main: "var(--cat-primary)",
};
export const easyTheme = {
  main: "var(--esx-easy)",
};
export const devopsTheme = {
  main: "var(--esx-devops)",
};
