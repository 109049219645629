import styled from "styled-components";
import media from "styled-media-query";
import { ChevronRight } from "styled-icons/typicons/ChevronRight";
import { StopMini } from "styled-icons/remix-fill/StopMini";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export const SquareIcon = styled(StopMini)`
  height: 1.2rem;
`;

export const ArrowRightIcon = styled(ChevronRight)`
  height: 1.2rem;
`;
export const GptwWrapper = styled.div`
  height: 220px;
  width: 110px;
  ${media.lessThan("large")`
    margin-bottom: 2rem
  `}
`;

//styled components
export const SliderWrapper = styled.section`
  height: 80vh;
  .each-slide > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-size: cover;
    height: 80vh;
  }
  .indicator {
    z-index: 0;
    margin-top: -4rem;
    padding: 0.5rem;
    cursor: pointer;
    text-align: center;
    color: #fafafa;
  }
  .indicator.active {
    svg {
      transition: transform 500ms;
      transform: scale(2);
    }
    color: var(--esx-red);
  }
`;
export const Slide = styled(BackgroundImage)`
  &::before {
    background-position-x: left !important;
    background-size: cover !important;
  }
  &::after {
    background-position-x: left !important;
    background-size: cover !important;
  }
`;
export const SliderContentWrapper = styled.div``;

export const SliderText = styled.div`
  position: absolute;
  margin-left: auto;
  height: 100%;
  width: 100%;
  display: flex;
  text-align: right;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 var(--default-padding-desktop)
    var(--default-padding-desktop) 50%;
  color: ${(props) => props.theme.color};

  ${media.lessThan("large")`
    padding: 0 var(--default-padding-mobile);
    width: 100%;
    align-items: center;
    text-align: center;
    background-image: linear-gradient(
    to bottom,
    transparent -60%,
    ${(props) => props.theme.background}
  );
  `}
`;

export const Title = styled.h1`
  font-size: var(--large-title-size);
  font-weight: bolder;
  line-height: 5rem;
  margin-bottom: 1rem;
  ${media.lessThan("large")`
    font-size: var(--medium-title-size);
    font-weight: 600;
    line-height: 2rem;
  `}
`;
export const Subtitle = styled.h2`
  font-size: var(--paragraph-size);
  margin-bottom: 2rem;
  ${media.lessThan("large")`
    font-size: var(--label-size);
  `}
`;

export const LinkLabel = styled(Link)`
  color: ${(props) => props.theme.color};
  font-size: var(--paragraph-size);
  ${media.lessThan("large")`
    font-size: var(--paragraph-size);
  `}
  &:hover {
    color: ${(props) => props.theme.color};
  }
`;

//themes
export const mainTheme = {
  color: "#fafafa",
  background: "var(--dark-gradient)",
};

SliderWrapper.defaultProps = {
  theme: {
    color: "#fafafa",
    background: "var(--dark-gradient)",
  },
};
