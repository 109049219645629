import React, { useEffect } from "react";
import "../bulma.scss";

import CatLayout from "../components/CatLayout";
import SEO from "../components/seo";
import CustomerColumns from "../components/CustomerColumns";
import PartnerColumns from "../components/PartnerColumns";
import CasesSection from "../components/CasesSection";
import ServicesSection from "../components/ServicesSection";
import SlideShow from "../components/SlideShow";
import SliderSeeMore from "../components/SliderSeeMore";
import CtaBanner from "../components/CtaBanner";
import HeroSection from "../components/HeroSection";
// import PageLoader from "../components/PageLoader";

const Home = () => {
  const cases = [
    {
      label: "DevOps Consulting",
      theme: "devops",
      buttonText: "Saiba mais",
      title: "DevOps Consulting",
      description:
        "Uma consultoria objetiva e sustentável para apoiar empresas e seus times de tecnologia no desenvolvimento e lançamento de software e aplicações com muito mais agilidade, qualidade e segurança.",
      textColor: "#fafafa",
      url: "/devops-consulting",
      caseIndex: 2,
    },
    {
      label: "Easy Cloud and Applications",
      theme: "easy",
      buttonText: "Saiba mais",
      title: "Easy Cloud and Applications",
      description:
        "Um serviço de aquisição, implementação e otimização de tecnologias baseadas em Cloud Computing, utilizando de forma inteligente e estratégica os recursos dos maiores provedores do mundo para o seu negócio.",
      textColor: "#fafafa",
      url: "/easy-cloud-and-applications",
      caseIndex: 1,
    },
    {
      label: "Customer Analytics Tool",
      theme: "cat",
      buttonText: "Saiba mais",
      title: "Customer Analytics Tool",
      description:
        "A Customer Analytics Tool é uma solução de Inteligência Artificial desenvolvida para auxiliar empresas de médio e grande porte a conhecerem melhor seus clientes e suas operações, ajudando a gerar insights para times de Atendimento, Compliance, Marketing e Vendas.",
      textColor: "#0d0d0d",
      url: "/cat",
      caseIndex: 0,
    },
  ];
  const jobs = [
    {
      label: "DevOps Consulting",
      theme: "esx",
      buttonText: "Veja nossas vagas",
      title: "Que tal fazer parte da equipe ESX?",
      description:
        "Uma empresa dedicada com o seu desenvolvimento pessoal e profissional",
      textColor: "#fafafa",
      url: "/jobs",
      caseIndex: 3,
    },
  ];
  return (
    <>
      {/* {showPage ? null : <PageLoader />} */}
      <CatLayout>
        <SEO title="ESX" />
        <SlideShow />
        <SliderSeeMore />
        <ServicesSection />
        <PartnerColumns title="Parceiros" />
        <CasesSection cases={cases} />
        <CustomerColumns title="Clientes" />
        <CasesSection forceRevert={true} cases={jobs} />
      </CatLayout>
    </>
  );
};

export default Home;
