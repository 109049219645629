import styled from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { ChevronDown } from "styled-icons/octicons/ChevronDown";

export const ArrowDownIcon = styled(ChevronDown)`
  margin-left: 1rem;
  height: 1rem;
`;

//styled components
export const SeeMoreWrapper = styled.div`
  height: 15vh;
  width: 100%;
  background-image: var(--light-gradient);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
`;
export const Button = styled(AnchorLink)`
  border: 1px solid var(--esx-red);
  color: var(--esx-red);
  padding: 1rem 2rem;
  border-radius: 100px;
  &:hover {
    color: var(--esx-red);
  }
`;
export const BorderWrapper = styled.span`
  height: 0.5rem;
  width: 100%;
  background-image: var(--light-gradient);
  display: flex;
  justify-content: center;
`;

export const Border = styled.span`
  width: 80%;
  height: 0.5rem;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: var(--esx-red);
  position: absolute;
`;
