import React from "react";
import * as S from "./styled";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import Gptw from "../../images/svgs/logo-selo-gptw.svg";

const SlideShow = () => {
  const { rocketImg, rocketImg2 } = useStaticQuery(
    graphql`
      query {
        rocketImg: file(
          relativePath: { eq: "banner-img-gptw.jpg" }
        ) {
          childImageSharp {
            fluid(
              maxWidth: 1960
              maxHeight: 720
              quality: 100
              fit: CONTAIN
              toFormat: WEBP
              cropFocus: WEST
              background: "#ef2345"
            ) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
        rocketImg2: file(relativePath: { eq: "banner-home-2_1400x900.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 1960
              maxHeight: 720
              quality: 100
              fit: CONTAIN
              toFormat: WEBP
              cropFocus: WEST
              background: "#ef2345"
            ) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
      }
    `
  );
  const slideImages = [rocketImg, rocketImg2];

  const sliderPropertys = {
    indicators: true,
    transitionDuration: 300,
    duration: 5000,
    Easing: "ease",
    arrows: false,
    indicators: () => (
      <div className="indicator">
        <S.SquareIcon />
      </div>
    ),
  };
  return (
    <S.SliderWrapper className="slide-container">
      <Slide {...sliderPropertys}>
        <ThemeProvider
          theme={{
            color: "#fafafa",
            background: "var(--esx-red)",
          }}
        >
          <S.SliderContentWrapper className="each-slide">
            <S.Slide fluid={slideImages[0].childImageSharp.fluid}>
                <S.SliderText>
                  <S.GptwWrapper>
                    <Gptw height="auto" width="auto"/>
                  </S.GptwWrapper>
                  <S.Title>Só tecnologia não basta</S.Title>
                  <S.Subtitle>
                    Pessoas vem primeiro
                  </S.Subtitle>
                  <S.LinkLabel to="/jobs">
                    Venha trabalhar com a gente <S.ArrowRightIcon />
                  </S.LinkLabel>
                </S.SliderText>
            </S.Slide>
          </S.SliderContentWrapper>
        </ThemeProvider>
        <ThemeProvider
          theme={{
            color: "#fafafa",
            background: "var(--esx-red)",
          }}
        >
          <S.SliderContentWrapper className="each-slide">
            <S.Slide fluid={slideImages[1].childImageSharp.fluid}>
              <S.SliderText>
                <S.GptwWrapper>
                  <Gptw height="auto" width="auto"/>
                </S.GptwWrapper>
                  <S.Title>Só tecnologia não basta</S.Title>
                  <S.Subtitle>
                    São necessárias ações verdadeiramente centradas em pessoas
                  </S.Subtitle>
                  <S.LinkLabel to="/cultura">
                    Nossa cultura <S.ArrowRightIcon />
                  </S.LinkLabel>
              </S.SliderText>
            </S.Slide>
          </S.SliderContentWrapper>
        </ThemeProvider>
      </Slide>
    </S.SliderWrapper>
  );
};

export default SlideShow;
