import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import * as S from "./styled";
import BorderedTitle from "../BorderedTitle";

const CustomerColumns = ({ title }) => {
  const { customers } = useStaticQuery(
    graphql`
      query CustomerData {
        customers: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "//collections/clientes//" } }
          sort: { fields: frontmatter___title }
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                image {
                  childImageSharp {
                    fluid(
                      maxWidth: 1500
                      maxHeight: 800
                      quality: 100
                      fit: CONTAIN
                      background: "#fff"
                    ) {
                      src
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const sliderPropertys = {
    indicators: false,
    transitionDuration: 300,
    duration: 100000000,
    Easing: "ease",
    arrows: true,
  };

  const customersGrouped = customers.edges.reduce(
    (r, e, i) => (i % 5 ? r[r.length - 1].push(e) : r.push([e])) && r,
    []
  );
  console.log(customersGrouped);

  return (
    <>
      <S.HeroSection>
        <BorderedTitle title={title} align={"center"} />
        <S.ItemRow>
          <S.SliderWrapper className="slide-container">
            <Slide {...sliderPropertys}>
              {customersGrouped.map((g, i) => {
                return (
                  <S.SliderContentWrapper className="each-slide" key={i}>
                    <S.Slide>
                      {g.map((customer, index) => (
                        <S.ItemCol>
                          <S.ItemImage>
                            <Img
                              fluid={
                                customer.node.frontmatter.image.childImageSharp
                                  .fluid
                              }
                              alt={customer.node.frontmatter.title}
                              title={customer.node.frontmatter.title}
                            />
                          </S.ItemImage>
                        </S.ItemCol>
                      ))}
                    </S.Slide>
                  </S.SliderContentWrapper>
                );
              })}
            </Slide>
          </S.SliderWrapper>
        </S.ItemRow>
      </S.HeroSection>
    </>
  );
};

export default CustomerColumns;
