import React from "react";
import * as S from "./styled";

const SliderSeeMore = () => (
  <>
    <S.SeeMoreWrapper>
      <S.ButtonWrapper>
        <S.Button to="/#services-section">
          Continuar Explorando
          <S.ArrowDownIcon />
        </S.Button>
      </S.ButtonWrapper>
    </S.SeeMoreWrapper>
    <S.BorderWrapper>
      <S.Border id="services-section" />
    </S.BorderWrapper>
  </>
);

export default SliderSeeMore;
