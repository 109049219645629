import styled from "styled-components";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import media from "styled-media-query";

export const CaseWrapper = styled.section`
  height: 75vh;
  display: flex;
  ${media.lessThan("medium")`
    flex-direction: column !important;
    height: auto;
  `}
`;
export const CaseBorder = styled.span`
  height: 50%;
  width: 0.5rem;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  background-color: ${(props) => props.theme.main};
  position: absolute;
  ${media.lessThan("medium")`
    height: 30%;
  `}
`;
export const CaseItem = styled.div`
  background: ${(props) => props.theme.background};
  width: 50%;
  display: flex;
  align-items: center;
  ${media.lessThan("medium")`
    width: 100%;
  `}
`;
export const CaseItemTextWrapper = styled.div`
  padding: var(--default-padding-desktop);
  color: #fafafa;
  width: 100%;

  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
`;
export const CaseItemTextTitle = styled.h1`
  font-size: var(--medium-title-size);
  font-weight: bold;
  margin: 0.5rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  svg {
    margin: 0;
    padding: 0;
  }
  ${media.lessThan("medium")`
    flex-direction: row ;
  `}
`;
export const CaseItemTextLabel = styled.label`
  text-transform: uppercase;
  font-size: var(--label-size);
  letter-spacing: 0.1rem;
`;
export const CaseItemText = styled.p`
  padding-bottom: 2rem;
`;
export const CaseItemTextButton = styled(Link)`
  border: 2px solid ${(props) => props.theme.main};
  padding: 0.5rem 2rem;
  border-radius: 100px;
  color: #fafafa;
  transition: all 300ms;
  &:hover {
    color: ${(props) => props.theme.background};
    background-color: ${(props) => props.theme.main};
  }
`;

export const CaseImage = styled(BackgroundImage)`
  width: 50%;
  ${media.lessThan("medium")`
    width: 100%;
    height: 15rem;
  `}
`;
export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to left, transparent 50%, #331b65 95%);
  ${media.lessThan("medium")`
  background: linear-gradient(to left, transparent, transparent) !important;

  `}
`;

CaseBorder.defaultProps = {
  theme: {
    main: "var(--esx-red)",
    background: "#371c69",
  },
};

//themes
export const catTheme = {
  main: "var(--cat-primary)",
  background: "#371c69",
};
export const esxTheme = {
  main: "#fafafa",
  background: "var(--esx-red)",
};
export const easyTheme = {
  main: "var(--esx-easy)",
  background: "#371c69",
};
export const devopsTheme = {
  main: "var(--esx-devops)",
  background: "#371c69",
};
export const invertStyleCaseWrapper = {
  flexDirection: "row-reverse",
};
export const invertStyleCaseItemWrapper = {
  backgroundImage: `linear-gradient(
    to left,
    #371c69,
    #371c69,
    
  )`,
};
export const invertGradientImage = {
  backgroundImage: `linear-gradient(to right, transparent 50%, #331b64 95%)`,
};

export const invertStyleCaseTextWrapper = {
  textAlign: "left",
};
export const invertStyleCaseItemTitle = {
  flexDirection: "row",
};
export const invertStyleBorder = {
  right: 0,
  marginRight: "auto",
  borderRadius: "100px 0 0px 100px",
};
