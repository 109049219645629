import styled from "styled-components";
import media from "styled-media-query";

//styled components
export const HeroSection = styled.section`
  padding: 2rem var(--default-padding-desktop);
  height: auto;
  ${media.lessThan("medium")`
    padding: 2rem var(--default-padding-mobile);
  `}
`;
export const ItemRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;
export const ItemCol = styled.div`
  height: auto;
  width: 350px;
`;
export const ItemImage = styled.figure`
  height: 100%;
  width: 100%;
`;
