import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import * as S from "./styled";
import BorderedTitle from "../BorderedTitle";

const PartnerColumns = ({ title }) => {
  const { partners } = useStaticQuery(
    graphql`
      query PartnerData {
        partners: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "//collections/parceiros//" } }
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                image {
                  childImageSharp {
                    fluid(
                      maxWidth: 1500
                      maxHeight: 800
                      quality: 100
                      fit: CONTAIN
                      background: "#fafafa"
                    ) {
                      src
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <S.HeroSection>
        <BorderedTitle title={title} align={"center"} />
        <S.ItemRow>
          {partners.edges.map((partner, i) => {
            return (
              <S.ItemCol key={i}>
                <S.ItemImage>
                  <Img
                    fluid={partner.node.frontmatter.image.childImageSharp.fluid}
                    alt={partner.node.frontmatter.title}
                    title={partner.node.frontmatter.title}
                  />
                </S.ItemImage>
              </S.ItemCol>
            );
          })}
        </S.ItemRow>
      </S.HeroSection>
    </>
  );
};

export default PartnerColumns;
